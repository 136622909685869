<template>
  <div class="container">
    <div style="text-align:right;margin-bottom:24px">
      <el-button
        type="primary"
        style="width:120px;"
        @click="toAdd"
        v-if="module_id_array.includes('57')||module_id_array.includes('999')"
      >新增</el-button>
    </div>
    <div style="border: solid 1px #c9cbd6;">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;min-width:1000px"
        :header-cell-style="{background:'#F5F5F5'}"
      >
        <el-table-column
          label="模板ID"
          prop="shipping_id"
        ></el-table-column>
        <el-table-column
          label="模板名称"
          prop="shipping_name"
        ></el-table-column>
        <el-table-column
          label="快递公司"
          prop="company_name"
        ></el-table-column>
        <el-table-column
          label="最后编辑时间"
          prop="update_time"
        ></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if='scope.row.status==1'>启用</span>
            <span
              class="red"
              v-if='scope.row.status==0'
            >禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row.shipping_id)"
              v-if="module_id_array.includes('57')||module_id_array.includes('999')"
            >编辑</el-button>
            <el-button
              type="text"
              @click="status(scope.row)"
              v-if="module_id_array.includes('59')||module_id_array.includes('999')"
            >{{scope.row.status==1?'禁用':'启用'}}</el-button>
            <el-button
              type="text"
              @click="del(scope.row.shipping_id)"
              v-if="scope.row.status==0&&(module_id_array.includes('58')||module_id_array.includes('999'))"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top:24px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalNum: 0,
      currentPage: 1,
      pagesize: 10,
      tableData: [],
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },
  methods: {
    //获取运费列表
    getList() {
      let page = this.currentPage;
      let pageNumber = this.pagesize;
      this.axios
        .post("/store/Shopshipping/getShopShippingList", { page, pageNumber })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data.list.map((item) => {
              item.update_time = this.dayjs(item.update_time * 1000).format(
                "YYYY-MM-DD"
              );
              return item;
            });
            this.totalNum = res.data.total;
          }
        });
    },

    //每页数据
    handleSizeChange(size) {
      this.pagesize = size;
      this.getList();
    },
    //翻页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    //新增模板
    toAdd() {
      this.$router.push("/system/addfreight");
    },
    //编辑模板
    edit(shipping_id) {
      this.$router.push({ path: "/system/addfreight", query: { shipping_id } });
    },
    status(row) {
      let data = {
        shipping_id: row.shipping_id,
        status: row.status == 1 ? 0 : 1,
      };
      this.axios
        .post("/store/Shopshipping/editShopShippingStatus", data)
        .then((res) => {
          this.getList();
          this.$message.success("修改成功");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除模板
    del(shipping_id) {
      this.$confirm("确定删除该模板吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "我再想想",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/store/Shopshipping/delShopShipping", { shipping_id })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功");
                this.getList();
              }
            });
        })
        .catch(() => {});
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.mydivider {
  margin: 0;
}

.btnbox {
  text-align: left;
  margin: 20px 0;
  padding-left: 14px;
}
.searchbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .item {
    margin-right: 30px;
    margin-top: 24px;
  }
  .searchtitle {
    display: inline-block;
    width: 60px;
    text-align: right;
  }
}
</style>
